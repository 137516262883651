import React, { useCallback, useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import cls from 'classnames';
import { useCartContext } from '~/ducks/shopping/shopping-context';
import shoppingCartIcon from '~/static/shopping-cart.svg';
import style from './header.module.scss';

const CartText = ({ totalOfNumberItems }: { totalOfNumberItems: number }) => {
  return (
    <span>
      Cart
      {totalOfNumberItems > 0 && ` (${totalOfNumberItems})`}
    </span>
  );
};

const CartHeaderIcon = () => {
  const {
    cartModalIsOpen,
    shoppingCart,
    hasCartItems,
    toggleCartModalIsOpen,
    getTotalNumberOfItems,
  } = useCartContext();
  const [animate, setAnimate] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const animationTimeout = useRef<NodeJS.Timeout>();
  const notificationTimeout = useRef<NodeJS.Timeout>();
  const hasRendered = useRef<boolean>(false);
  const totalOfNumberItems = getTotalNumberOfItems();

  // Handle click of shopping cart
  const handleOnClick = useCallback(async () => {
    const isOnShopping = document.location.pathname.includes('shop');

    if (!isOnShopping) {
      await navigate('/shop/');
    }

    if (isOnShopping || hasCartItems()) {
      toggleCartModalIsOpen();
      setShowNotification(false);
    }
  }, [toggleCartModalIsOpen, hasCartItems]);

  // Animate shopping cart icon when shopping cart has changed
  useEffect(() => {
    if (!hasRendered.current) {
      return;
    }

    if (animationTimeout.current) {
      clearTimeout(animationTimeout.current);
    }

    setAnimate(true);
    animationTimeout.current = setTimeout(() => {
      setAnimate(false);
    }, 200);
  }, [shoppingCart]);

  // Show shopping notification when shopping cart has changed
  useEffect(() => {
    if (!hasRendered.current) {
      return;
    }

    if (notificationTimeout.current) {
      clearTimeout(notificationTimeout.current);
    }

    // Dont show notification if cart is empty
    if (!hasCartItems()) {
      setShowNotification(false);
      return;
    }

    setShowNotification(true);

    notificationTimeout.current = setTimeout(() => {
      setShowNotification(false);
    }, 2500);
  }, [shoppingCart, hasCartItems]);

  // Hide notification when user opens or closes cartModal
  useEffect(() => {
    setShowNotification(false);
    if (notificationTimeout.current) {
      clearTimeout(notificationTimeout.current);
    }
  }, [cartModalIsOpen]);

  // Don't animate icon the first time the component is rendered
  useEffect(() => {
    hasRendered.current = true;
  }, []);

  return (
    <>
      <span onClick={handleOnClick} className={style.shoppingCartWrapper}>
        <img
          src={shoppingCartIcon}
          title="Show shopping cart"
          alt="Shopping"
          className={cls({
            [style.scaleUp]: animate && !cartModalIsOpen,
          })}
        />
        <CartText totalOfNumberItems={totalOfNumberItems} />
      </span>
      {showNotification && !cartModalIsOpen && (
        <span
          className={cls(style.shoppingNotification, style.visible)}
          onClick={handleOnClick}
        >
          <div>Cart updated.</div>
          <div>Click here to view cart.</div>
        </span>
      )}
    </>
  );
};

export default CartHeaderIcon;
