import React from 'react';
import '../../../styles/style.scss';
import Footer from '../footer/footer';
import Header from '../header/header';
import style from './layout.module.scss';

interface Props {
  blackHeader?: boolean;
  sticky?: boolean;
}

const Layout: React.FC<Props> = ({ blackHeader, sticky, children }) => (
  <>
    <Header black={blackHeader} sticky={sticky} />
    <main className={style.main}>{children}</main>
    <Footer />
  </>
);

export default Layout;
