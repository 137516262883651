import React from 'react';
import { Link } from 'gatsby';
import cls from 'classnames';
import greenStarImage from '~/static/green-star.png';
import infidelLogo from '~/images/method-draw-image.svg';
import SocialMediaLinks from './social-media-links';
import CartHeaderIcon from './cart-header-icon';
import style from './header.module.scss';

const GreenStarImage = () => <img src={greenStarImage} alt="Green star" />;

interface Props {
  black: boolean;
  sticky: boolean;
}

const Header = ({ black, sticky }: Props) => {
  return (
    <header
      className={cls(style.Header, {
        [style.black]: black,
        [style.sticky]: sticky,
      })}
    >
      <input type="checkbox" id={style.menuCheckbox} />
      <div className={style.contentInner}>
        <label htmlFor={style.menuCheckbox} id={style.menuToggle}>
          <span />
          <span />
          <span />
        </label>
        <Link to="/" className={style.logo}>
          <img src={infidelLogo} width="120" alt="Infidel Comics Logo" />
        </Link>
        <nav className={style.social}>
          <SocialMediaLinks />
          <CartHeaderIcon />
        </nav>
      </div>

      <nav className={style.burgerMenu}>
        <ul>
          <Link to="/" activeClassName={style.active}>
            <GreenStarImage />
            <span>Home</span>
          </Link>
          <Link to="/trailers/" activeClassName={style.active}>
            <GreenStarImage />
            <span>Trailers</span>
          </Link>
          <Link to="/shop/" activeClassName={style.active}>
            <GreenStarImage />
            <span>Shop</span>
          </Link>
          <Link to="/synopsis/" activeClassName={style.active}>
            <GreenStarImage />
            <span>Synopsis</span>
          </Link>
          <Link to="/team/" activeClassName={style.active}>
            <GreenStarImage />
            <span>Team</span>
          </Link>
          <Link to="/characters/" activeClassName={style.active}>
            <GreenStarImage />
            <span>Characters</span>
          </Link>
        </ul>
        <nav className={style.socialInBurger}>
          <SocialMediaLinks />
        </nav>
      </nav>
    </header>
  );
};

Header.defaultProps = {
  black: true,
  sticky: true,
};

export default Header;
