import React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '~/hooks/use-metadata-hooks';
import heroImage from '~/static/hero4.jpg';

interface Props {
  title: string;
  description?: string;
  lang?: string;
  meta?: {
    content: string;
    name?: string;
    property?: string;
  }[];
}

const SEO = ({ description, lang, meta, title }: Props) => {
  const siteMetadata = useSiteMetadata();
  const metaDescription = description || siteMetadata.description;
  const fullHeroImageUrl = `${siteMetadata.url}${heroImage}`;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
    >
      <meta name="description" content={metaDescription} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={fullHeroImageUrl} />
      <meta property="og:url" content={siteMetadata.url} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary" />
      {/*<meta name="twitter:creator" content={siteMetadata.author} />*/}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />

      {meta?.map((metaData) => (
        <meta key={metaData.name} {...metaData} />
      ))}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
