import { graphql, useStaticQuery } from 'gatsby';
import type { FluidObject } from 'gatsby-image';
import type { ImageFileName } from '~/image-filenames';

export const useFluidImage = (
  imageName: ImageFileName
): FluidObject | undefined => {
  const { allImages } = useGetAllImages();
  return findImageWithName(imageName, allImages.nodes)?.fluid;
};

type GetAllImagesResult = Array<{
  fluid: FluidObject & {
    originalName: ImageFileName;
  };
}>;

interface ImageQueryResult {
  allImages: { nodes: GetAllImagesResult };
}
const findImageWithName = (
  imageName: ImageFileName,
  nodes: GetAllImagesResult
) => {
  return nodes.find((node) => node.fluid.originalName === imageName);
};

const useGetAllImages = (): ImageQueryResult => {
  const result = useStaticQuery<ImageQueryResult>(graphql`
    {
      allImages: allImageSharp {
        nodes {
          fluid {
            originalName
            ...GatsbyImageSharpFluid_withWebp_noBase64
            #            aspectRatio
            #            src
            #            srcSet
            #            sizes
          }
        }
      }
    }
  `);

  return result;
};
