import React from 'react';
import Img, { GatsbyImageProps } from 'gatsby-image';
import { useFluidImage } from '~/hooks/use-images-hooks';
import { ImageFileName } from '~/image-filenames';

export interface Props extends Omit<GatsbyImageProps, 'fluid'> {
  imageName: ImageFileName;
}

const FluidImage = ({ imageName, ...otherProps }: Props) => {
  const fluidImageObject = useFluidImage(imageName);
  // @ts-ignore
  const defaultImage = useFluidImage('green-star.png' as any);

  if (!fluidImageObject) {
    console.error('Image not found:', imageName);
    return <Img fluid={defaultImage} {...otherProps} />;
  }

  return <Img fluid={fluidImageObject} {...otherProps} />;
};

export default React.memo(FluidImage);
