import React from 'react';
import OutLink from '~/components/common/OutLink/OutLink';
import { useSocialMedia } from '~/hooks/use-metadata-hooks';
import * as socialImages from '~/static/social';

const SocialMediaLinks = () => {
  const socialMedia = useSocialMedia();

  return (
    <>
      <OutLink href={socialMedia.facebook}>
        <img src={socialImages.Facebook} alt="Facebook" />
      </OutLink>
      <OutLink href={socialMedia.instagram}>
        <img src={socialImages.Instagram} alt="Instagram" />
      </OutLink>
      <OutLink href={socialMedia.twitter}>
        <img src={socialImages.Twitter} alt="Twitter" />
      </OutLink>
      <OutLink href={socialMedia.soundcloud}>
        <img src={socialImages.Soundcloud} alt="Soundcloud" />
      </OutLink>
    </>
  );
};

export default SocialMediaLinks;
