import React from 'react';

export interface Props {
  href: string;
  className?: string;
}

const OutLink: React.FC<Props> = ({ href, className, children }) => {
  return (
    <a
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      {children}
    </a>
  );
};

export default OutLink;
