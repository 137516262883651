import React from 'react';
import { useSiteMetadata } from '~/hooks/use-metadata-hooks';
import FluidImage from '../../common/FluidImage/FluidImage';
import style from './footer.module.scss';

const Footer = () => {
  const siteMetaData = useSiteMetadata();

  return (
    <footer className={style.Footer}>
      <div className={style.footerContainer}>
        <FluidImage imageName="logga_v2.png" />
        <p>All rights reserved</p>
        <a href={`mailto:${siteMetaData.email.personal}`}>
          {siteMetaData.email.personal}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
